<template>
  <LayoutFilter :toggleCollapseEnable="false" :onFilter="ok" :onReset="resetForm">
    <el-form :model="filterForm" :rules="rules" ref="filterFilterForm" label-width="75px" size="medium"
             class="filter-from" @submit.native.prevent>
      <div class="filter-item">
        <!--        当一个 form 元素中只有一个输入框时，在该输入框中按下回车应提交该表单。
如果希望阻止这一默认行为，可以 在 标签上添加 @submit.native.prevent。-->
        <el-form-item label="用户名称：" prop="nickname">
          <el-input @keyup.enter.native="ok" v-model="form.nickname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input @keyup.enter.native="ok" v-model="form.phone" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker labelWidth="100px" label="最后登录时间：" start-prop="login_start_time" :start-time.sync="form.login_start_time"
                          end-prop="login_end_time" :end-time.sync="form.login_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
  export default {
    name: "ListFilter",
    components: { DatePeriodPicker, LayoutFilter },
    props: {
      filterForm: {
        type: Object,
      },
    },
    data() {
      return {
        rules: {},
        form: null,
      };
    },
    watch: {
      filterForm: {
        deep: true,
        handler(val) {
          this.form = val;
        },
      },
    },
    created() {
      this.form = this.filterForm;
    },
    methods: {
      //重置表单
      resetForm() {
        this.$refs["filterFilterForm"].resetFields();
        this.ok()
      },
      //筛选
      ok() {
        this.$emit("update", this.form);
      },
    },
  };
</script>

<style scoped>
</style>


