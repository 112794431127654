import api from "@/base/utils/request";

export const getList = (data) => {
  return api({
    url: '/admin/cyc/noncyc_user/index',
    method: 'post',
    data
  })
}

export const getDetail = (id) => {
  return api({
    url: '/admin/cyc/noncyc_user/detail',
    method: 'post',
    data: {id}
  })
}

export const saveDetail = (data) => {
  return api({
    url: '/admin/cyc/noncyc_user/save',
    method: 'post',
    data
  })
}
