<template>
  <list-layout ref="listLayout"
               :filter-form="filterForm"
               :thead="thead"
               :on-fetch="getList"
               :on-edit="handleAdd">
    <template #top>
      <el-button class="top-btn" size="small" type="primary" @click="handleAdd()">添加游客</el-button>
    </template>
    <template #filter>
      <list-filter :filter-form="filterForm" @update="ok" />
    </template>
    <el-dialog :visible="dialog.show" class="dialog-vertical" @close="close"
               :title="dialog.data.id ? '修改资料':'添加'" width="500px">
      <el-form class="medium-form" size="medium" v-loading="dialog.editLoading"
               label-width="85px" label-position="right" :model="dialog.data">
        <el-form-item label="头像：">
          <div class="avatar">
            <single-media-wall ratio="200:200" v-model="dialog.data.avatar" addText="" :width="85" :height="85" borderRadius="50%"/>
          </div>
          <div class="info">
            <i class="el-icon-info" />上传尺寸比例200*200
          </div>
        </el-form-item>
        <el-form-item label="用户名称：" required>
          <el-input v-model="dialog.data.nickname" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="手机号码：" required>
          <el-input type="number" v-model="dialog.data.phone" placeholder="请输入" :maxlength="13" show-word-limit />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="confirm" :disabled="dialog.editLoading" :loading="dialog.loading">确定</el-button>
      </template>
    </el-dialog>
  </list-layout>
</template>

<script>
  import ListLayout from "@/base/layout/ListLayout";
  import {getDetail, getList, saveDetail} from "@/modules/non-cyc-user/api/list";
  import ListFilter from "@/modules/non-cyc-user/components/ListFilter";
  import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

  const DIALOG_MODEL = {
    id: '',
    avatar: '',
    nickname: '',
    phone: ''
  }

  export default {
    name: "list",
    components: {SingleMediaWall, ListLayout, ListFilter},
    data() {
      return {
        filterForm: {
          "page": 1,
          "page_size": 15,
          "is_desc": 1,
          "order_by":"id",
          "nickname": "",
          "phone":"",
          "login_start_time":-1,
          "login_end_time": -1
        },
        thead: [
          // { label: "", prop: "selection", width: 80},
          { label: "ID", prop: "id", width: 140 },
          { label: "头像", prop: "avatar", minWidth: 100 },
          { label: "用户名称", prop: "nickname", minWidth: 150 },
          { label: "手机号码", prop: "phone", minWidth: 200 },
          { label: "最后登录时间", prop: "last_login_time", minWidth: 200 },
          {label: "操作", type: "operation", width: 100, operations: [{command: 'edit', text: '修改资料'}]}
        ],
        dialog: {
          show: false,
          data: JSON.parse(JSON.stringify(DIALOG_MODEL)),
          editLoading: false,
          loading: false,
        }
      }
    },
    methods: {
      /**
       * 列表
       * @param params
       * @returns {AxiosPromise}
       */
      getList(params) {
        return getList(params)
      },
      /**
       * 新增、编辑
       * @param obj
       */
      handleAdd(obj = {}) {
        if (obj.id) {
          this.dialog.editLoading = true
          getDetail(obj.id).then(res => {
            res.data.avatar = res.data.avatar ? typeof res.data.avatar === 'string' ? res.data.avatar : '' : ''
            this.dialog.data = this.$mergeModel(DIALOG_MODEL, res.data)
            this.dialog.editLoading = false
          }).catch(err => {
            this.dialog.editLoading = false
          })
        } else {
          this.dialog.data = JSON.parse(JSON.stringify(DIALOG_MODEL))
        }
        this.dialog.show = true
      },
      // 筛选回调
      ok(e) {
        this.filterForm = { ...this.filterForm, ...e, page: 1 };
        this.$refs.listLayout.getList(this.filterForm)
      },
      /**
       * 弹窗
       */
      confirm() {
        this.dialog.loading = true
        saveDetail(this.dialog.data).then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$refs.listLayout.getList(this.filterForm)
        }).catch(err => {
        }).finally(() => {
          this.dialog.loading = false;
        })
      },
      close() {
        this.dialog.show = false
      }
    },
  }
</script>

<style scoped>

</style>
